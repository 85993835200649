import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Profiles } from 'data/Profiles';

const ArticlePreview = ({ post }) => {
  const { id, title, articleImageUrl, date, category, description, author } = post;
  const [profile, setProfile] = useState(null);
  
  useEffect(() => {
    let name = author.toLowerCase()
    if (author && Profiles[name]) {
      setProfile(Profiles[name]);
    }
  },[author])
  
  return (
    <div>
      <Link to={`/article/${id}`}>
        <div className="flex flex-col md:flex-row items-center">
          <div className="w-full md:w-52 md:h-52 flex-shrink-0 mb-4 md:mb-0 md:mr-6">
            <img
              className="w-full h-full object-cover rounded-3xl shadow-lg"
              src={articleImageUrl}
              alt={title}
            />
          </div>
          <div className="flex flex-col flex-end w-full">
            <div className="text-sm text-gray-500">{date}</div>
            <div className="text-sm text-blue-500 mb-2">{category}</div>
            <h3 className="text-2xl font-bold mb-2">{title}</h3>
            <p className="text-gray-700 mb-4">{description}</p>
            <div className="flex items-center mb-4">
              <img
                src={profile && profile.image_ref}
                alt="Author"
                className="w-8 h-8 rounded-full mr-2"
              />
              <div>
                <div className="text-gray-900 font-semibold">{author}</div>
                <div className="text-gray-500">{profile && profile.title}</div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ArticlePreview;
