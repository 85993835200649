import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import 'index.css';

import Landing from "views/Landing.jsx";
import Profile from "views/Profile.jsx";
import Blog from "views/Blog.jsx";
import Contact from "views/Contact.jsx";
import FAQ from "views/FAQ.jsx";
import NotFound from "views/NotFound.jsx";
import Article from 'views/Article';

// Get the root container
const container = document.getElementById('root');

// Create a root
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// Initial render
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Landing />} />
      <Route path="/profile/:name" element={<Profile />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/article/:id" element={<Article />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/faq" element={<FAQ />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  </BrowserRouter>
);
