import Navbar from "components/Navbar.jsx";
import Footer from "components/Footer.jsx";
import { Link, useParams } from 'react-router-dom';
import { useState, useEffect, React } from 'react';
import { Posts } from 'data/Posts.js';
import Polygon from 'components/Polygon.jsx';
import { Profiles } from 'data/Profiles';

const Article = () => {
  const { id } = useParams();
  const post = Posts[id];

  const [name, setName] = useState('');
  const [profile, setProfile] = useState({});
  
  useEffect(() => {
    setName(post.author.toLowerCase())
    if (name && Profiles[name]) {
      setProfile(Profiles[name]);
    } else {
      setProfile(null);
    }
  },[post, name, profile])

  return (
    <>
      <div className="flex flex-col min-h-screen">
        <Navbar transparent />
        <main className="flex-grow">
          <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
              style={{ minHeight: "40vh" }}>
            <div className="absolute top-0 w-full h-full bg-center bg-cover"
                style={{ backgroundImage: `url(${post.articleImageUrl})` }}>
              <span id="blackOverlay" className="w-full h-full absolute opacity-25 bg-black"></span>
            </div>
            <Polygon color="text-gray-900" />
          </div>
          <div className="relative py-2 flex content-center items-center justify-center bg-gray-900">
            <Polygon />
          </div>
          <div className="flex flex-col items-center mt-16 mb-24 max-w-6xl mx-auto px-12 xl:px-0">
            <h2 className="text-4xl font-bold mb-4">{post.title}</h2>
            <div className="text-sm text-gray-500 mb-2">{post.date}</div>
            <div className="text-sm text-blue-500 mb-2">{post.category}</div>
            <div className="prose prose-lg max-w-none py-8" dangerouslySetInnerHTML={{ __html: post.content }}></div>

            { profile &&
              <Link to={`/profile/${profile.name?.toLowerCase()}`} >
                <div className="flex items-center my-4">
                  <img
                    src={profile.image_ref}
                    alt={profile.img_alt}
                    className="w-12 h-12 rounded-full mr-4"
                  />
                  <div>
                    <div className="text-gray-900 font-semibold">{profile.name}</div>
                    <div className="text-gray-500">{profile.title}</div>
                  </div>
                </div>
              </Link>
            }
          </div>
        </main>
        <Footer transparent />
      </div>
    </>
  );
};

export default Article;
