import Navbar from "components/Navbar.jsx";
import Footer from "components/Footer.jsx";
import { useParams } from 'react-router-dom';
import { Profiles } from 'data/Profiles';
import { useEffect, useState } from 'react';
import { TfiWorld } from "react-icons/tfi";

export default function Profile() {
  const { name } = useParams();
  const [profile, setProfile] = useState({});
  
  useEffect(() => {
    if (name && Profiles[name]) {
      setProfile(Profiles[name]);
    } else {
      setProfile(null);
    }
  },[name, profile])
  
  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      <main className="profile-page flex-grow">
        <section className="relative block" style={{ minHeight: "45vh" }}>
        <div
            className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage:
                "url('https://images.unsplash.com/photo-1499336315816-097655dcfbda?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=2710&q=80')",
              backgroundSize: 'cover'
            }}
          >
            <span
              id="blackOverlay"
              className="w-full h-full absolute opacity-50 bg-black"
            ></span>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"w
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
                stroke="currentColor"
                strokeWidth="5"
              ></polygon>
            </svg>
          </div>
        </section>
        <section className="relative py-24 bg-gray-900">
          <div className="container mx-auto px-4 pb-8 2xl:pb-16">
            <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg -mt-64">
              <div className="px-6">
                <div className="text-center mt-14">
                  <img
                    src={profile.image_ref}
                    alt={profile.img_alt}
                    className="mx-auto mb-6 h-48 w-48 object-cover rounded-full border-4 border-gray-300"
                  />
                  <h3 className="text-4xl font-semibold leading-normal mb-2 text-gray-800">
                    { profile.name }
                  </h3>
                  <div className="text-sm leading-normal mt-0 mb-2 text-gray-500 font-bold uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-gray-500"></i>
                      { profile.location }
                  </div>
                  <div className="mb-2 text-gray-700">
                    <i className="fas fa-briefcase mr-2 text-lg text-gray-500"></i>
                     { profile.title }
                  </div>
                  <div className="mb-2 text-gray-700">
                    <i className="fas fa-university mr-2 text-lg text-gray-500"></i>
                     { profile.education }
                  </div>
                  { profile.personal_website_url && (
                    <div className="mb-2 text-gray-700">
                    <i className="fas fa-web mr-2 text-lg text-gray-500"><TfiWorld />
                    </i>
                    <a href={profile.personal_website_url} target="_blank" rel="noreferrer">kelsey-jackson.com</a>
                  </div>  
                  )}
                </div>
                <div className="mt-10 py-10 border-t border-gray-300 text-center">
                  <div className="flex flex-wrap justify-center">
                    <div className="w-full lg:w-9/12 px-4">
                      <p className="mb-4 text-lg leading-relaxed text-gray-800">
                        { profile.about }
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </div>
  );
}
