const questions = [
    {
      id: 1,
      question: 'What is your main service?',
      answer: 'We provide custom software solutions to help businesses streamline their operations and achieve their goals.',
    },
    {
      id: 2,
      question: 'How do I get started with your service?',
      answer: 'You can get started by contacting us through our Contact Us page.',
    },
    {
      id: 3,
      question: 'What industries do you serve?',
      answer: 'We serve a wide range of industries, including small business\', healthcare, retail, and more.',
    },
    {
      id: 4,
      question: 'What is your pricing model?',
      answer: 'Our pricing model is flexible and based on the specific needs of your business. Please contact us for a customized quote.',
    },
    {
      id: 5,
      question: 'Do you offer support after project completion?',
      answer: 'Yes, we offer ongoing support and maintenance to ensure your software continues to run smoothly.',
    },
    {
      id: 7,
      question: 'What is the typical timeline for a project?',
      answer: 'The timeline for a project varies depending on its complexity and scope. We will provide an estimated timeline after discussing your specific requirements.',
    },
    {
      id: 8,
      question: 'How do you ensure the security of our data?',
      answer: 'We prioritize data security and implement robust measures to protect your data, including encryption, audits, and compliance with industry standards.',
    },
  ];
  
  export default questions