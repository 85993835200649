export const Profiles = {
  "andrew": {
    "id": 1,
    "name": "Andrew",
    "image_ref": "/assets/img/andrew.jpg",
    "img_alt": "",
    "location": "Grand Rapids, MI",
    "title": "CEO",
    "education": "Grand Valley State University | Criminal Justice & Public Administration MPA",
    "about": "Andrew has a passion for local business and has worked alongside small business owners to reach their goals for over five years. After he earned his Undergraduate degree while competing on the Grand Valley State University swim and dive team (Go Lakers), he realized his passion for service and worked as a Police Officer. During his time in public service, he earned a master’s degree in public management from GVSU and received the honor of Excellence in Public Service from GVSU. He now works to serve local communities through his expertise in business management including assisting multiple non-profits in the Grand Rapids area for the past 5 years in management, marketing, and sales. He has a passion for bringing together people with big ideas to accomplish great things. When he isn’t working on his business idea, Andrew enjoys rock climbing, board games with friends, and learning history.",
    "linkedin_url": "",
    "personal_website_url": "",
    "instagram_url": ""
    },
  "mike": {
    "id": 2,
    "name": "Mike",
    "image_ref": "/assets/img/mike.png",
    "img_alt": "Mike with his baby",
    "location": "Grand Rapids, MI",
    "title": "CSO",
    "education": "Grand Valley State University | Broadcast Communication",
    "about": "Michael Mason is a former news junkie turned entrepreneur. He worked in award winning local news stations in Michigan and South Carolina as a Chief photojournalist before switching to sales. Currently he is raising his son and working on entrepreneurial projects.",
    "linkedin_url": "",
    "personal_website_url": "",
    "instagram_url": ""
    },
  "gabe": {
    "id": 3,
    "name": "Gabe",
    "image_ref": "/assets/img/gabe.jpg",
    "img_alt": "",
    "location": "Grand Rapids, MI",
    "title": "CTO",
    "education": "Grand Valley State University | Computer Science",
    "about": "With extensive experience in enterprise website development and cloud engineering, I bring a wealth of knowledge and expertise to our team. I have successfully developed websites and tools for billion-dollar companies, showcasing my ability to deliver high-quality solutions for complex needs. Currently, I serve as a Senior Cloud Infrastructure Engineer at a leading pharmaceutical startup, where I continue to hone my skills in innovative and cutting-edge technology. My commitment to excellence and my passion for developing robust, scalable infrastructure ensure that our clients receive the best possible service and solutions.",
    "linkedin_url": "",
    "personal_website_url": "",
    "instagram_url": ""
    },
  "aaron": {
    "id": 4,
    "name": "Aaron",
    "image_ref": "/assets/img/aaron.jpg",
    "img_alt": "",
    "location": "Grand Haven, MI",
    "title": "Principal Engineer",
    "education": "Calvin University | Civil & Environmental Engineering",
    "about": "Drawing on my background in engineering, my passion for language learning, and my talent for software development, I offer a unique combination of technical and creative skills to any project. My focus is on crafting visually appealing and user-friendly software that effectively addresses the requirements of both clients and users, and I am dedicated to consistently delivering top-notch results.",
    "linkedin_url": "",
    "personal_website_url": "",
    "instagram_url": ""
    },
  "kelsey": {
    "id": 5,
    "name": "Kelsey",
    "image_ref": "/assets/img/kelsey.png",
    "img_alt": "",
    "location": "New Hartford, CT",
    "title": "Web Developer",
    "education": "Mimo Web Development Bootcamp",
    "about": "I am a passionate individual focused on front end web development. I love to use code to bring out my inner creativity. Self-learning is a trait I've embraced and I enjoy absorbing as much as I can to bring out the best in myself and others.",
    "linkedin_url": "https://www.linkedin.com/in/kelsey-jackson-22b098256/",
    "personal_website_url": "https://kelsey-jackson.com/",
    "instagram_url": ""
  }
}
