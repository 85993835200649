import { useState } from 'react';
import { NavLink } from 'react-router-dom';

export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header className="no-cursor bg-gray-600 bg-opacity-60 w-full flex justify-between items-center px-8 fixed top-0 left-0 z-50">
      <div className="relative flex-shrink-0">
        <div className="logo-background">
          <NavLink to="/">
            <img src="/assets/img/logo.png" alt="Cloud Step Logo" className="h-40 w-auto logo" />
          </NavLink>
        </div>
      </div>

      {/* Hamburger Icon */}
      <div className="lg:hidden">
        <button onClick={toggleMenu} className="text-white focus:outline-none">
          <svg
            className="w-8 h-8"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
          </svg>
        </button>
      </div>

      {/* Nav Links */}
      <nav
        className={`${
          isOpen ? 'block' : 'hidden'
        } lg:flex lg:space-x-10 mr-10 text-xl absolute lg:relative top-full left-0 lg:top-auto lg:left-auto w-full lg:w-auto bg-gray-600 bg-opacity-60 lg:bg-transparent`}>
        <div className="lg:flex lg:space-x-10 flex flex-col lg:flex-row items-center lg:items-center">
          <NavLink
            to="/"
            className={({ isActive }) =>
              isActive ? 'text-white border-b-2 border-white my-1' : 'text-white nav-link my-1'
            }
          >
            Home
          </NavLink>
          <NavLink
            to="/blog"
            className={({ isActive }) =>
              isActive ? 'text-white border-b-2 border-white my-1' : 'text-white nav-link my-1'
            }
          >
            Blog
          </NavLink>
          <NavLink
            to="/faq"
            className={({ isActive }) =>
              isActive ? 'text-white border-b-2 border-white my-1' : 'text-white nav-link my-1'
            }
          >
            FAQ
          </NavLink>
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              isActive ? 'text-white border-b-2 border-white my-1' : 'text-white nav-link my-1'
            }
          >
            Contact
          </NavLink>
        </div>
      </nav>
    </header>
  );
}
