import Footer from 'components/Footer.jsx';
import Navbar from 'components/Navbar.jsx';
import { useState } from 'react';

const apiUrl = process.env.REACT_APP_API_URL;

export default function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    business: '',
    message: ''
  });

  const [isSubmitted, setIsSubmitted] = useState(false);

  const formatPhoneNumber = (value) => {
    // Remove all non-numeric characters
    const cleaned = value.replace(/\D/g, '');

    // Apply formatting based on the number of digits
    const length = cleaned.length;
    if (length <= 3) {
      return cleaned;
    } else if (length <= 6) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}`;
    } else if (length <= 10) {
      return `${cleaned.slice(0, 3)}-${cleaned.slice(3, 6)}-${cleaned.slice(6, 10)}`;
    } else {
      // Handle numbers with country code
      const countryCodeLength = length - 10;
      return `+${cleaned.slice(0, countryCodeLength)}-${cleaned.slice(countryCodeLength, countryCodeLength + 3)}-${cleaned.slice(countryCodeLength + 3, countryCodeLength + 6)}-${cleaned.slice(countryCodeLength + 6, countryCodeLength + 10)}`;
    }
  };

  const [errors, setErrors] = useState({
    email: '',
    phone: '',
    name: '',
    message: ''
  });

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhone = (phone) => {
    const phoneRegex = /^(\+\d{1,3}-)?\d{3}-\d{3}-\d{4}$/; // Format: +X-XXX-XXX-XXXX or XXX-XXX-XXXX
    return phoneRegex.test(phone);
  };

  const handleChange = (e) => {
    const { id, value } = e.target;

    let formattedValue = value;
    if (id === 'phone') {
      formattedValue = formatPhoneNumber(value);
    }
    setFormData((prevData) => ({
      ...prevData,
      [id]: formattedValue,
    }));


    // Validate email and phone on change
    if (id === 'email') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: validateEmail(formattedValue) ? '' : 'Invalid email address',
      }));
    }

    if (id === 'phone') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        phone: validatePhone(formattedValue) ? '' : 'Invalid phone number',
      }));
    }

    if (id === 'name') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        name: value ? '' : 'Full name is required',
      }));
    }

    if (id === 'message') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        message: value ? '' : 'Message is required',
      }));
    }
  };
  const validate_form = () => {
    let email_error = validateEmail(formData.email) ? '' : 'Invalid email address';
    let phone_error = validatePhone(formData.phone) ? '' : 'Invalid phone number';
    let name_error = formData.name ? '' : 'Full name is required';
    let message_error = formData.message ? '' : 'Full name is required';

    setErrors((prevErrors) => ({
      ...prevErrors,
      message: message_error,
      email: email_error,
      phone: phone_error,
      name: name_error
    }))


    // check if there are any errors
    if (email_error || phone_error || name_error || message_error) {
      return false;
    }
    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    var form_validation = validate_form();
    console.log(form_validation);
    if (!form_validation) {
      alert('Please fix any errors before submitting the form.');
      return
    }
    try {
      // check if local host in url
      var request = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      }
      const is_local_host = window.location.href.includes('localhost');
      if (is_local_host) {
        // make a copy of the form data
        var formDataCopy = { ...formData };
        formDataCopy.name = "LOCAL TESTING: " + formDataCopy.name;
        request.body = JSON.stringify(formData); request = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      }
      var response = await fetch(apiUrl + '/email', request)
      console.log(response);
      if (response.status === 200) {
        setIsSubmitted(true); // Set submission status to true
      } else {
        // Handle error response
        alert('Failed to send message.');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <Navbar />
      
      <main className="flex-grow pb-40 border-b border-gray-300">
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
          style={{
            minHeight: "60vh"
          }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover"
            style={{
              backgroundImage: "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
            }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
          </div>
          <div className="container relative mx-auto">
            <div className="items-center flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                <div className="pr-12">
                  <h1 className="text-white font-semibold text-5xl">
                    Contact Us
                  </h1>
                  <p className="mt-4 text-lg text-gray-300">
                    We would love to hear from you! Please fill out the form below and we will get in touch with you shortly.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100"
                stroke="currentColor"
                strokeWidth="5"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="relative py-24 bg-gray-900">
          <div className="container mx-auto px-4 ">
            <div className="flex flex-wrap justify-center">
              <div className="w-full lg:w-8/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 -mt-40">
                  <div className="flex-auto p-5 lg:p-10">
                    {isSubmitted ? ( // Check if the form was submitted
                      <div className="text-center">
                        <h4 className="text-2xl font-semibold">
                          Thank You!
                        </h4>
                        <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                          Thank you for reaching out. We will get back to you as quickly as possible.
                        </p>
                      </div>
                    ) : (
                      <div>
                        <h4 className="text-2xl font-semibold">
                          Want to work with us?
                        </h4>
                        <p className="leading-relaxed mt-1 mb-4 text-gray-600">
                          Complete this form and we will get back to you in 24 hours.
                        </p>
                        <form onSubmit={handleSubmit}>
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="name"
                            >
                              Full Name
                            </label>
                            <input
                              type="text"
                              id="name"
                              className="border-none px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder="Full Name"
                              value={formData.name}
                              onChange={handleChange}
                              style={{ transition: "all .15s ease" }}
                            />
                            {errors.name && (
                              <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                            )}
                          </div>
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="email"
                            >
                              Email
                            </label>
                            <input
                              type="email"
                              id="email"
                              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder="Email"
                              value={formData.email}
                              onChange={handleChange}
                              style={{ transition: "all .15s ease" }}
                            />
                            {errors.email && (
                              <p className="text-red-500 text-xs mt-1">{errors.email}</p>
                            )}
                          </div>

                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="phone"
                            >
                              Phone Number
                            </label>
                            <input
                              type="text"
                              id="phone"
                              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder="Phone Number"
                              value={formData.phone}
                              onChange={handleChange}
                              style={{ transition: "all .15s ease" }}
                            />
                            {errors.phone && (
                              <p className="text-red-500 text-xs mt-1">{errors.phone}</p>
                            )}
                          </div>

                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="business"
                            >
                              Business Name (Optional)
                            </label>
                            <input
                              type="text"
                              id="business"
                              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder="Business Name"
                              value={formData.business}
                              onChange={handleChange}
                              style={{ transition: "all .15s ease" }}
                            />
                          </div>
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-gray-700 text-xs font-bold mb-2"
                              htmlFor="message"
                            >
                              Message
                            </label>
                            <textarea
                              rows="4"
                              id="message"
                              className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                              placeholder="Type a message..."
                              value={formData.message}
                              onChange={handleChange}
                            />
                            {errors.message && (
                              <p className="text-red-500 text-xs mt-1">{errors.message}</p>
                            )}
                          </div>

                          <div className="text-center mt-6">
                            <button
                              className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1"
                              type="submit"
                              style={{ transition: "all .15s ease" }}
                            >
                              Send Message
                            </button>
                          </div>
                        </form>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <svg
            className="absolute bottom-0 left-0 right-0 top-auto overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-white fill-current"
              points="2560 0 2560 100 0 100"
              stroke="currentColor"
              strokeWidth="5"
            ></polygon>
          </svg>
        </section>

      </main>
      <Footer />
    </div>
  );
}
