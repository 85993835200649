import Navbar from "components/Navbar.jsx";
import Footer from "components/Footer.jsx";
import WhatWeDo from "components/WhatWeDo.jsx";
import { Profiles } from 'data/Profiles';
import { useNavigate, Link } from 'react-router-dom';

export default function Landing() {
  const navigate = useNavigate();
  const sortedProfiles = Object.entries(Profiles).sort(([, a], [, b]) => a.id - b.id)

  const handleProfileClick = (name) => {
    navigate(`/profile/${name}`);
  };

  return (
    <>
      <Navbar />
      <main>
        <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
            style={{
              minHeight: "75vh"
            }}>
          <div className="absolute top-0 w-full h-full bg-center bg-cover border-b border-b-gray-300"
              style={{
                backgroundImage: "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
              }}>
            <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
          </div>
          <div className="container relative mx-auto">
              <div className="items-center flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                  <div className="pr-12">
                    <h1 className="text-white font-semibold text-5xl">
                      Grow with us.
                    </h1>
                    <p className="mt-4 text-lg text-gray-300">
                      Streamline your business and boost productivity on web based solutions with our dedicated team.
                    </p>
                  </div>
                </div>

              </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden "
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden border-none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current border-none"
                points="2560 0 2560 100 0 100"
                stroke="currentColor"
                strokeWidth="5"
              ></polygon>
            </svg>
          </div>
        </div>

        <section className="pb-20 bg-gray-300 -mt-24 border-b border-none ">
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap">
              <div className="lg:pt-12 pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-red-400">
                      <i className="fas fa-award"></i>
                    </div>
                    <h6 className="text-xl font-semibold">Innovative software solutions</h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Empower your business with our cutting-edge software, tailored to help you reach your goals.
                    </p>
                  </div>
                </div>
              </div>

              <div className="w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-blue-400">
                      <i className="fas fa-retweet"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Seamless Integrations
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Your business will be propelled forward by our cloud-based solutions that integrate effortlessly.
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6 w-full md:w-4/12 px-4 text-center">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 shadow-lg rounded-lg">
                  <div className="px-4 py-5 flex-auto">
                    <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 mb-5 shadow-lg rounded-full bg-green-400">
                      <i className="fas fa-fingerprint"></i>
                    </div>
                    <h6 className="text-xl font-semibold">
                      Trusted to Transform Your Business
                    </h6>
                    <p className="mt-2 mb-4 text-gray-600">
                      Trust your business to us and join a growing community who we partner with to elevate them to their goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex flex-wrap items-center mt-32">
              <div className="w-full md:w-5/12 px-4 mr-auto ml-auto pb-6 lg:pb-0">
                <div className="text-gray-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-gray-100">
                  <i className="fas fa-user-friends text-xl"></i>
                </div>
                <h3 className="text-3xl mb-2 font-semibold leading-normal">
                  Partnering with you is our pleasure
                </h3>
                <p className="text-lg font-light leading-relaxed mt-4 mb-4 text-gray-700">
                  Leverage our expertise and software solutions to transform your business and achieve your goals.
                </p>
                <p className="text-lg font-light leading-relaxed mt-0 mb-4 text-gray-700">
                  Our user-friendly and customizable solutions empower you to adapt swiftly to market change, ensuring your continued success.
                </p>
                <Link  className="font-bold text-gray-800 mt-8" to={`/contact`}>
                  Partner with Us for Success
                </Link>
              </div>

              <div className="w-full md:w-4/12 px-4 mr-auto ml-auto ">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg">
                  <img
                    alt="..."
                    src="https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1051&q=80"
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block border-none"
                      style={{
                        height: "95px",
                        top: "-94px"
                      }}
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-pink-600 fill-current"
                        stroke="currentColor"
                        strokeWidth="5"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-gray-800">
                      Exceptional Support
                    </h4>
                    <p className="text-lg font-light mt-2 text-gray-700">
                      Our dedicated team is here to assist you, ensuring you get the most out of our software solutions.
                    </p>
                  </blockquote>
                </div>
              </div>

            </div>
          </div>
        </section>

        <section className="relative py-20 border-none">
          <div
            className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden border-none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current border-none"
                points="2560 0 2560 100 0 100"
                stroke="currentColor"
                strokeWidth="5"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 pb-12">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4 pb-12 lg:pb-0">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.unsplash.com/photo-1555212697-194d092e3b8f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <div className="text-pink-600 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-pink-300">
                    <i className="fas fa-rocket text-xl"></i>
                  </div>
                  <h3 className="text-3xl font-semibold">
                    Grow with a Dedicated Start-Up
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                    You know your dreams and where you're going. Our team is devoted to helping you reach your goals with our software solutions. Partner with us for a dedicated, agile ally that will make your targets, our obsession.
                  </p>
                  <ul className="list-none mt-6">
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fas fa-fingerprint"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">
                            Innovative Solutions
                          </h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="fab fa-html5"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Personalized Service</h4>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="flex items-center">
                        <div>
                          <span className="text-xs font-semibold inline-block py-1 px-2 uppercase rounded-full text-pink-600 bg-pink-200 mr-3">
                            <i className="far fa-paper-plane"></i>
                          </span>
                        </div>
                        <div>
                          <h4 className="text-gray-600">Scalable Solutions</h4>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div
            className="top-auto bottom-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden border-b border-gray-300"
            style={{ height: "70px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden border-none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-300 fill-current border-none"
                points="2560 0 2560 100 0 100"
                stroke="currentColor"
                strokeWidth="5"
              ></polygon>
            </svg>
          </div>
        </section>

        <section className="pt-4 pb-28 bg-gray-300">
          <WhatWeDo/>
        </section>

        <section className="pt-20 pb-40 relative block border-none">
            <div
                className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
                style={{ height: "80px" }}
              >
            <svg
              className="absolute bottom-0 overflow-hidden border-none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-white fill-current border-none"
                points="2560 0 2560 100 0 100"
                stroke="currentColor"
                strokeWidth="5"
              ></polygon>
            </svg>
          </div>
          <div className="container mx-auto px-4">
            <div className="flex flex-wrap justify-center text-center mb-24">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold">
                  Meet the Team Behind Your Future Success
                </h2>
                <p className="text-lg leading-relaxed m-4 text-gray-600">
                  At Cloud Step, our team is passionate about delivering exceptional software solutions. We work tirelessly to ensure our services meet your highest standards and support your unique needs.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap justify-center">
              {sortedProfiles.map(([key, profile]) => (
                <div
                  key={profile.id}
                  className="w-full md:w-6/12 xl:w-4/12 2xl:w-2/12 mb-12 px-4 cursor-pointer"
                  onClick={() => handleProfileClick(key)}
                >
                  <div className="px-6">
                    <img
                      src={profile.image_ref}
                      alt={profile.img_alt}
                      className="shadow-lg rounded-full max-w-full mx-auto"
                      style={{ maxWidth: "120px" }}
                    />
                    <div className="pt-6 text-center">
                      <h5 className="text-xl font-bold">
                        {profile.name}
                      </h5>
                      <p className="mt-1 text-sm text-gray-500 uppercase font-semibold">
                        {profile.title}
                      </p>
                      <p className="mt-1 text-sm text-gray-500">
                        {profile.location}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>

        <section className="-mb-24 relative block bg-gray-900 py-24 pb-48 lg:py-0 border-none">
          <div
            className="bottom-0 top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20  border-none"
            style={{ height: "80px" }}
          >
            <svg
              className="absolute bottom-0 overflow-hidden border-none"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="text-gray-900 fill-current border-none"
                points="2560 0 2560 100 0 100"
                stroke="currentColor"
                strokeWidth="5"
              ></polygon>
            </svg>
          </div>

          <div className="container mx-auto px-4 lg:pt-24 lg:pb-64">
            <div className="flex flex-wrap text-center justify-center">
              <div className="w-full lg:w-6/12 px-4">
                <h2 className="text-4xl font-semibold text-white">
                Transform Your Business
                </h2>
                <p className="text-lg leading-relaxed mt-4 mb-4 text-gray-500">
                  Partner with us to develop custom software solutions that drive growth and efficiency. Our expertise helps you streamline processes and achieve your business goals with innovative technology.
                </p>
              </div>
            </div>
            <div className="flex flex-wrap mt-12 justify-center">
              <div className="w-full lg:w-3/12 px-4 text-center py-4 lg:py-0">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-medal text-xl"></i>
                </div>
                <h6 className="text-xl mt-5 font-semibold text-white">
                  Exceptional Quality
                </h6>
                <p className="mt-2 mb-4 text-gray-500">
                  We deliver top-notch software solutions tailored to your needs, ensuring high performance and reliability.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center py-4 lg:py-0">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-poll text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Expand Your Reach
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                  Leverage our solutions to access new markets and grow your customer base, enhancing your business's impact and success.
                </p>
              </div>
              <div className="w-full lg:w-3/12 px-4 text-center py-4 lg:py-0">
                <div className="text-gray-900 p-3 w-12 h-12 shadow-lg rounded-full bg-white inline-flex items-center justify-center">
                  <i className="fas fa-lightbulb text-xl"></i>
                </div>
                <h5 className="text-xl mt-5 font-semibold text-white">
                  Rapid Deployment
                </h5>
                <p className="mt-2 mb-4 text-gray-500">
                  Get your software solutions up and running quickly with our streamlined development process, ensuring timely delivery and efficient results.
                </p>
              </div>
            </div>
          </div>
        </section>

      </main>
      <Footer />
    </>
  );
}
