import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCode, faPaintBrush, faMobileAlt, faServer, faBullhorn, faChartLine } from '@fortawesome/free-solid-svg-icons';

const services = [
  { icon: faCode, title: 'Web Development', description: 'Building responsive and modern websites.' },
  { icon: faPaintBrush, title: 'UI/UX Design', description: 'Crafting user-friendly and beautiful designs.' },
  { icon: faMobileAlt, title: 'Mobile Development', description: 'Creating mobile applications for all platforms.' },
  { icon: faServer, title: 'Backend Development', description: 'Developing robust backend systems and APIs.' },
  { icon: faBullhorn, title: 'Digital Marketing', description: 'Promoting your business online effectively.' },
  { icon: faChartLine, title: 'SEO Optimization', description: 'Improving your site’s search engine rankings.' },
];

const WhatWeDo = () => {
  return (
    <div className="container mx-auto py-12 px-4 border-none">
      <h2 className="text-4xl font-semibold text-center mb-8">What We Do</h2>
      <ul className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
        {services.map((service, index) => (
          <li
            key={index}
            className="p-6 border-2 border-dashed hover:cursor-pointer hover:border-solid hover:border-pink-500 rounded-lg transition duration-300 ease-in-out"
          >
            <FontAwesomeIcon icon={service.icon} className="text-4xl text-pink-500 mb-4" />
            <h3 className="text-xl font-semibold mb-2">{service.title}</h3>
            <p>{service.description}</p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default WhatWeDo;
