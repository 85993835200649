import { Link } from 'react-router-dom';

export default function Footer() {
  return (
    <footer className="relative bg-gray-300 pt-8 pb-6">
      <div
        className="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
        style={{ height: "80px" }}
      >
        <svg
          className="absolute bottom-0 overflow-hidden"
          xmlns="http://www.w3.org/2000/svg"
          preserveAspectRatio="none"
          version="1.1"
          viewBox="0 0 2560 100"
          x="0"
          y="0"
        >
          <polygon
            className="text-gray-300 fill-current"
            points="2560 0 2560 100 0 100"
            stroke="currentColor"
            strokeWidth="5"
          ></polygon>
        </svg>
      </div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap">
          <div className="w-full lg:w-6/12 px-4">
            <h4 className="text-3xl font-semibold">
              Let's keep in touch!
            </h4>
            <h5 className="text-lg mt-0 mb-2 text-gray-700">
              Reach out to us, and we will get back to you ASAP.
            </h5>
            <div className="my-6">
              <a href="https://www.facebook.com/cloudstep2" target="_blank" rel="noreferrer">  
                <button
                  className="bg-white text-blue-600 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                  type="button"
                >
                  <i className="flex fab fa-facebook-square"></i>
                </button>
              </a>
              <Link to="/contact">
                <button
                  className="bg-white text-gray-800 shadow-lg font-normal h-10 w-10 items-center justify-center align-center rounded-full outline-none focus:outline-none mr-2 p-3"
                  type="button"
                >
                  <i className="flex fas fa-envelope"></i>
                </button>
              </Link>
            </div>
          </div>
          <div className="w-full lg:w-6/12 px-4">
            <div className="flex flex-wrap items-top mb-6">

              <div className="w-full lg:w-4/12 px-4 ml-auto">
                <span className="block uppercase text-gray-600 text-sm font-semibold mb-2">
                  Resources
                </span>
                <ul className="list-unstyled">
                  <li>
                    <Link to="/"  className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/blog"  className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                      Blog
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq"  className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm">
                      FAQ
                    </Link>
                  </li>
                  <li>
                    <Link to="/contact" className="text-gray-700 hover:text-gray-900 font-semibold block pb-2 text-sm"
                      >Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <hr className="my-6 border-gray-400" />
        <div className="flex flex-wrap items-center md:justify-between justify-center">
          <div className="w-full md:w-4/12 px-4 mx-auto text-center">
            <div className="text-sm text-gray-600 font-semibold py-1">
              Copyright © {new Date().getFullYear()} Cloud Step
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
