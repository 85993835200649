import React from 'react';
import Navbar from "components/Navbar.jsx";
import Footer from "components/Footer.jsx";
import { Posts } from 'data/Posts';
import ArticlePreview from 'components/ArticlePreview.jsx';
import Polygon from 'components/Polygon.jsx';

const Blog = () => {
    // Convert the Posts object into an array for mapping
    const postIds = Object.keys(Posts);
    const postsArray = postIds.map(id => Posts[id]).reverse();

    return (
      <>
        <Navbar transparent />
        <main className="mx-auto pb-24">
          <div className="relative pt-16 pb-32 flex content-center items-center justify-center"
              style={{
                minHeight: "40vh"
              }}>
            <div className="absolute top-0 w-full h-full bg-center bg-cover"
                style={{
                  backgroundImage: "url('https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80')"
                }}>
              <span id="blackOverlay" className="w-full h-full absolute opacity-75 bg-black"></span>
            </div>
            <div className="container relative mx-auto">
                <div className="items-center flex flex-wrap">
                  <div className="w-full lg:w-6/12 px-4 ml-auto mr-auto text-center">
                    <div className="pr-12">
                      <h1 className="text-white font-semibold text-5xl">
                        Insights & Expertise
                      </h1>
                      <p className="mt-4 text-lg text-gray-300">
                        Discover expert tips and strategies to elevate your business.
                      </p>
                    </div>
                  </div>
                </div>
            </div>
            <Polygon color="text-gray-900" />
          </div>
          <div className="relative py-2 flex content-center items-center justify-center bg-gray-900">
            <Polygon />
          </div>

          <div className="grid md:grid-cols-1 gap-10 mt-24 mb-12 mx-auto max-w-6xl">
            <div className='px-20'>
              {postsArray.map((post, index) => (
                <div key={post.title} >
                  <ArticlePreview post={post} />
                  {index < postsArray.length - 1 && (
                    <hr className="border-t border-gray-300 mt-10 mb-12 lg:ml-60" />
                  )}
                </div>
              ))}
            </div>
          </div>
        </main>
        <Footer />
      </>
    );
};

export default Blog;
